import React, { Suspense } from "react";

import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

import { FirebaseAppProvider } from "reactfire";

import { Provider } from "react-redux";
import store from './redux/store'

import { firebaseConfig } from "./configs/firebaseConfig";

import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";

import App from "./App";

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <Suspense fallback="cargando...">
      <Provider store={store}>
        <BrowserRouter >
          <App />
        </BrowserRouter>
      </Provider>
    </Suspense>
  </FirebaseAppProvider>,
  document.getElementById("root")
);