
import { notification } from "antd";
import {
  RiCloseFill,
  RiCheckboxCircleFill,
  RiErrorWarningFill,
} from "react-icons/ri";

/**
message es el título
description es eso mismo
duration = 0 hace que solo se cierre manualmene. otro valor es tiempo en ms
**/

const notificacionCorrecto = (message = null, description = null, duration = 0) => {
  notification.open({
    message,
    description,
    duration,
    icon: <RiCheckboxCircleFill style={{ color: "#00F7BF" }} />,
    closeIcon: (
      <RiCloseFill className="remix-icon da-text-color-black-80" size={24} />
    ),
  });
};

const notificacionError = (message = null, description = null, duration = 0) => {
  notification.open({
    message,
    description,
    duration,
    icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
    closeIcon: (
      <RiCloseFill className="remix-icon da-text-color-black-80" size={24} />
    ),
  });
};

const notificacionAdvertencia = (message = null, description = null, duration = 0) => {
  notification.open({
    message,
    description,
    duration,
    icon: <RiErrorWarningFill style={{ color: "#FFC700" }} />,
    closeIcon: (
      <RiCloseFill className="remix-icon da-text-color-black-80" size={24} />
    ),
  });
};


const notificacionInformacion = (message = null, description = null, duration = 0) => {
  notification.open({
    message,
    description,
    duration,
    icon: <RiErrorWarningFill style={{ color: "#1BE7FF" }} />,
    closeIcon: (
      <RiCloseFill className="remix-icon da-text-color-black-80" size={24} />
    ),
  });
};

export { notificacionInformacion, notificacionError, notificacionCorrecto, notificacionAdvertencia }
