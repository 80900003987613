import { Link } from "react-router-dom";

import { Dropdown, Col, Avatar, Divider, Row } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { Calendar, Game, People, Folder, User } from "react-iconly";

import avatarImg from "../../../assets/images/memoji/memoji-1.png";
import { logout } from "../../../redux/user/userActions";

export default function HeaderUser() {

  const { email, emailVerified, displayName } = useSelector(
    (state) => state.user
  );

  const dispatch = useDispatch();

  const onSalir = () => {
    dispatch(logout());
  };

  const menu = (
    <div
      className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-p-24 hp-mt-12"
      style={{ width: 260 }}
    >
      <span className="hp-d-block h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mb-8">
        {displayName}
      </span>
      <span className="hp-d-block h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mb-8">
        {email}
      </span>

      <Link
        to="/change-password"
        className="hp-p1-body hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-hover-text-color-primary-2"
      >
        Cambiar contraseña
      </Link>

      <Divider className="hp-mt-16 hp-mb-6" />
      {/* 
      <Link to="/" className="hp-d-flex-center hp-p1-body hp-py-8 hp-px-10 hp-d-block hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-border-radius" style={{ marginLeft: -10, marginRight: -10 }}>
        <Folder
          set="curved"
          size={16}
        />
        <span className="hp-ml-8">Archivo</span>
      </Link>


      <Divider className="hp-mb-16 hp-mt-6" /> */}

      <Link to="/login" className="hp-p1-body" onClick={onSalir}>
        {/* // TODO SALIR CORRECTAMENTE DEL SISTEMA */}
        Salir
      </Link>
    </div>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Avatar size={40} className="hp-cursor-pointer" icon={<User set="curved" />}/>
      </Dropdown>
    </Col>
  );
};
