import { combineReducers } from "redux";

import customiseReducer from "./customise/customiseReducer";
import userReducer from "./user/userReducer";

const rootReducer = combineReducers({
  customise: customiseReducer,
  user: userReducer,
});

export default rootReducer;