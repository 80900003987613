
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  // createUserWithEmailAndPassword,
  // updatePhoneNumber,
  updateProfile,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
} from "firebase/firestore";

import { app, firestore } from "../../configs/firebaseConfig";
import { notificacionError } from "../../layout/components/content/notifications";
import * as userTypes from "./userTypes";

const loadKind = async (email) => {
  const q = query(
    collection(firestore, "users"),
    where("email", "==", email)
  );
  const snaps = await getDocs(q);
  if(snaps.empty){
    return [""];
  }
  const userdoc = snaps.docs[0];

  // const userdoc = await getDoc(doc(firestore, "users", uid));
  // // console.log("loadKind", userdoc);
  // if (!userdoc.exists()) {
  //   return [];
  // }

  const userData = userdoc.data();
  // console.log("userData",userData);
  return [userData.kind];
};

// USER DATA: lastName, firstName, department, country, roles;
// const agregarDatosUsuario = async (uid, userData) => {
//   await setDoc(doc(firestore, "users", uid), {
//     ...userData,
//   });
// };

const errorHandle = (error) => {
  const errorCode = error.code;
  const errorMessage = error.message;
  // console.log("errorHandle>>>>>>>>>>>>>>>>>",{errorCode, errorMessage});
  notificacionError(`ERROR ${errorCode}`, `${errorMessage}`,0);

};

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch({
      type: userTypes.LOADING,
    });
    const auth = getAuth(app);
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    ).catch(errorHandle);

    // console.log(userCredential);

    if (!userCredential) {
      dispatch({
        type: userTypes.LOGIN_FAILURE,
      });
    } else {
      const { user } = userCredential;

      if (user) {
        const {
          displayName,
          email,
          emailVerified,
          uid,
          // phoneNumber,
          // photoURL,
        } = user;
        const roles = await loadKind(email);
        dispatch({
          type: userTypes.LOGIN_SUCCESS,
          payload: {
            displayName,
            email,
            emailVerified,
            uid,
            // phoneNumber,
            // photoURL,
            roles,
          },
        });
      }
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    const auth = getAuth(app);
    await signOut(auth).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("LOGOUT", { errorCode, errorMessage });
    });
    dispatch({
      type: userTypes.LOGOUT,
    });
  };
};

export const currentUser = (userData) => {
  return async (dispatch) => {
    dispatch({
      type: userTypes.LOADING,
    });

    const roles = await loadKind(userData.email);
    dispatch({
      type: userTypes.CURRENT,
      payload: {
        ...userData,
        roles,
      },
    });
  };
};

// export const newRegularUser = (userData) => {
//   return async (dispatch) => {
//     dispatch({
//       type: userTypes.LOADING,
//     });

//     const auth = getAuth(app);
//     const { email,password, ...restUserData } = userData;
//     const userCredential = await createUserWithEmailAndPassword(
//       auth,
//       email,
//       password,
//     ).catch(errorHandle);
//     if (userCredential) {
//       const { user } = userCredential;
//       await updateProfile(user,{displayName:userData.firstName});
//       // await updatePhoneNumber()
//       const {uid, displayName,email:emailUser,emailVerified,phoneNumber,photoURL}=user;

//       await agregarDatosUsuario(uid,{email,...restUserData,roles:"customer"}).catch(errorHandle);

//       dispatch({
//         type: userTypes.LOGIN_SUCCESS,
//         payload: {
//           uid,
//           displayName,
//           email: emailUser,
//           emailVerified,
//           phoneNumber,
//           photoURL,
//           roles: "customer",
//         },
//       });
//     }

//     dispatch({
//       type: userTypes.LOGIN_FAILURE,
//     });
//   };
// };
