import * as userTypes from "./userTypes";

const INITIAL_STATE = {
  uid: "",
  displayName: "",
  email: "",
  emailVerified: false,
  phoneNumber: "",
  photoURL: "",
  roles: [],
  loading: false,
  authorized: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        authorized: true,
      };
    case userTypes.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        authorized: false,
      };

    case userTypes.LOADING:
      return {
        ...state,
        loading: true,
      };

    case userTypes.LOGOUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };

    case userTypes.CURRENT:
      return {
        ...state,
        ...action.payload,
        authorized: true,
        loading: false,
      };

    // case userTypes.SINGUP:
    //   return {
    //     ...state,
    //   };

    default:
      return state;
  }
};

export default userReducer;
