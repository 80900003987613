import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// import { getFunctions } from "firebase/functions";


export const firebaseConfig = {
  apiKey: "AIzaSyAwXbRGA2BHT6byEqpNK3nBBmncz_sq2C0",
  authDomain: "suppliersandparthersmanagment.firebaseapp.com",
  // databaseURL:
  //   "https://suppliersandparthersmanagment-default-rtdb.firebaseio.com",
  projectId: "suppliersandparthersmanagment",
  storageBucket: "suppliersandparthersmanagment.appspot.com",
  messagingSenderId: "957213082878",
  appId: "1:957213082878:web:75d3f71e3198b3100311af",
  measurementId: "G-C019VPFRR7",
};


export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
// const analytics = getAnalytics(app);
// const functions = getFunctions(app);