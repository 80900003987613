import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from './rootReducer'
// import { createStore, applyMiddleware, compose } from 'redux'

import { configureStore } from "@reduxjs/toolkit";

// const middleware = [thunk, createDebounce()]
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)))
const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, createDebounce()],
});

export default store;

