import { Col, Layout, Row } from "antd";

import themeConfig from '../../../configs/themeConfig.jsx';

export default function MenuFooter() {
  const { Footer } = Layout;
  
  return (
    <Footer className="hp-bg-color-black-10 hp-bg-color-dark-100">
      <Row align="middle" justify="space-between">
        <Col md={12} span={24}>
          <p className="hp-badge-text hp-mb-0 hp-text-color-dark-30">
            ©2022 
            <a
              href="https://exogrid.io"
              className="hp-badge-text hp-text-color-dark-30"
            >
              {' '}Exogrid
            </a>
          </p>
        </Col>

        <Col
          md={12}
          span={24}
          className="hp-mt-sm-8 hp-text-sm-center hp-text-right"
        >
          Version: {themeConfig.version}
        </Col>
      </Row>
    </Footer>
  );
};