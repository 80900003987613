import { CloseSquare, PaperPlus } from "react-iconly";
import IntlMessages from "../../layout/components/lang/IntlMessages";

const pages = [
  {
    header: "PROVEEDOR",
  },
  {
    id: "dataDocumentsPartners",
    title: "Datos y Documentos",
    icon: <PaperPlus set="curved" className="remix-icon" />,
    navLink: "/supplier/info-docs",
  },
];

export default pages;
