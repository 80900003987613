import { lazy } from "react";

const PagesRoutes = [
  //////////////
  //
  // AUTH
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/auth/login")),
    layout: "FullLayout",
  },
  {
    path: "/register",
    component: lazy(() => import("../../view/pages/auth/register")),
    layout: "FullLayout",
  },
  {
    path: "/recover-password",
    component: lazy(() => import("../../view/pages/auth/recover-password")),
    layout: "FullLayout",
  },
  {
    path: "/reset-password",
    component: lazy(() => import("../../view/pages/auth/reset-password")),
    layout: "FullLayout",
  },
  {
    path: "/email-verification",
    component: lazy(() => import("../../view/pages/auth/email-verification")),
    layout: "FullLayout",
  },

  /////////////////
  //
  // LEGAL
  {
    path: "/legal/privacy-terms",
    component: lazy(() => import("../../view/pages/legal/privacy-term")),
    layout: "FullLayout",
  },
  {
    path: "/legal/terms-conditions",
    component: lazy(() => import("../../view/pages/legal/terms-conditions")),
    layout: "FullLayout",
  },
  //////////////

  /////////////////
  //
  // PARTNER
  {
    path: "/partner/info-docs",
    component: lazy(() => import("../../view/pages/info-docs/partner")),
    layout: "VerticalLayout",
  },
  //////////////

  /////////////////
  //
  // SUPPLIER
  {
    path: "/supplier/info-docs",
    component: lazy(() => import("../../view/pages/info-docs/supplier")),
    layout: "VerticalLayout",
  },
  //////////////

  /////////////////
  //
  // ADMIN
  {
    path: "/admin/records",
    component: lazy(() => import("../../view/pages/admin/records")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/record-detail",
    component: lazy(() =>
      import("../../view/pages/admin/record-detail/partner")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/record-detail-supplier",
    component: lazy(() =>
      import("../../view/pages/admin/record-detail/supplier")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/records-approved",
    component: lazy(() => import("../../view/pages/admin/records-approved")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/records-unfiltered",
    component: lazy(() => import("../../view/pages/admin/records-unfiltered")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/record-history-supplier",
    component: lazy(() =>
      import("../../view/pages/admin/record-history/supplier")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/record-history-partner",
    component: lazy(() =>
      import("../../view/pages/admin/record-history/partner")
    ),
    layout: "VerticalLayout",
  },

  // {
  //   path: "/admin/records-approved/detail",
  //   component: lazy(() =>
  //     import("../../view/pages/admin/records-approved/partner")
  //   ),
  //   layout: "VerticalLayout",
  // },
  // {
  //   path: "/admin/partner-after-approval",
  //   component: lazy(() =>
  //     import("../../view/pages/admin/partner-after-approval")
  //   ),
  //   layout: "VerticalLayout",
  // },

  {
    path: "/admin/users/add-edit",
    component: lazy(() => import("../../view/pages/admin/users/1.add")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/users/list",
    component: lazy(() => import("../../view/pages/admin/users/6.list")),
    layout: "VerticalLayout",
  },

  {
    path: "/admin/proyects/list",
    component: lazy(() => import("../../view/pages/admin/projects/6.list")),
    layout: "VerticalLayout",
  },
  {
    path: "/admin/projects/item",
    component: lazy(() => import("../../view/pages/admin/projects/1.new-edit")),
    layout: "VerticalLayout",
  },

  //////////////

  /////////////////
  //
  // ALL
  {
    path: "/change-password",
    component: lazy(() =>
      import("../../view/pages/admin/users/3.password-change")
    ),
    layout: "VerticalLayout",
  },
  //////////////

  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/error-page",
    component: lazy(() => import("../../view/pages/error")),
    layout: "FullLayout",
  },
  {
    path: "/error/403",
    component: lazy(() => import("../../view/pages/error/403")),
    layout: "VerticalLayout",
    auth: false,
  },
  {
    path: "/error/full-403",
    component: lazy(() => import("../../view/pages/error/403")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/error/404",
    component: lazy(() => import("../../view/pages/error/404")),
    layout: "VerticalLayout",
    auth: false,
  },
  {
    path: "/error/501",
    component: lazy(() => import("../../view/pages/error/501")),
    layout: "VerticalLayout",
    auth: false,
  },
  {
    path: "/error/500",
    component: lazy(() => import("../../view/pages/error/500")),
    layout: "VerticalLayout",
    auth: false,
  },
  {
    path: "/error/502",
    component: lazy(() => import("../../view/pages/error/502")),
    layout: "VerticalLayout",
    auth: false,
  },
  {
    path: "/error/503",
    component: lazy(() => import("../../view/pages/error/503")),
    layout: "VerticalLayout",
    auth: false,
  },
  {
    path: "/coming-soon",
    component: lazy(() => import("../../view/pages/error/coming-soon")),
    layout: "VerticalLayout",
    auth: false,
  },
  {
    path: "/maintenance",
    component: lazy(() => import("../../view/pages/error/maintenance")),
    layout: "VerticalLayout",
    auth: false,
  },
];

export default PagesRoutes;