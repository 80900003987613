import React from "react";

import { Row } from "antd";

import { useSelector } from "react-redux";



import logo from "../../assets/images/logo/fm-logo-dark.svg";
import logoDark from "../../assets/images/logo/fm-logo-white.svg";

export default function Home() {
  const theme = useSelector((state) => state.customise.theme);

  return (
    <Row gutter={[32, 32]} align="middle" justify="center">
      <img src={theme == "light" ? logo : logoDark} width="70%" alt="LogoFM" />
    </Row>
  );
}
