import { Work, PaperPlus } from "react-iconly";
import { RiHandCoinLine, RiHandHeartLine, RiTeamFill } from "react-icons/ri";

import IntlMessages from "../../layout/components/lang/IntlMessages";

const pages = [
  {
    header: "ADMINISTRADOR",
  },
  {
    id: "recordsSuppliers",
    title: "Proveedores",
    icon: <RiHandCoinLine set="curved" className="remix-icon" />,
    items: [
      {
        id: "unfilteredRecordSupplier",
        title: "Proveedores Totales",
        navLink: "/admin/records-unfiltered?kind=Proveedor",
      },
      {
        id: "incompleteRecordSupplier",
        title: "Proveedores Incompletos",
        navLink: "/admin/records?stage=unfinish&kind=Proveedor",
      },
      {
        id: "reviewRecordSupplier",
        title: "Proveedores por Revisar",
        navLink: "/admin/records?stage=done&kind=Proveedor",
      },
      {
        id: "preApprovedRecordSupplier",
        title: "Proveedores Pre-Aprobados",
        navLink: "/admin/records?stage=preapproval&kind=Proveedor",
      },
      {
        id: "approvedRecordSupplier",
        title: "Proveedores Aprobados",
        navLink: "/admin/records-approved?kind=Proveedor",
      },
      // {
      //   id: "detailRecordSupplier",
      //   title: "Detalle de registro",
      //   navLink: "/admin/record-detail",
      // },
    ],
  },
  {
    id: "recordsPartners",
    title: "Colaboradores",
    icon: <RiHandHeartLine set="curved" className="remix-icon" />,
    items: [
      {
        id: "unfilteredRecordPartners",
        title: "Colaboradores Totales",
        navLink: "/admin/records-unfiltered?kind=Colaborador",
      },
      {
        id: "incompleteRecordPartners",
        title: "Colaboradores Incompletos",
        navLink: "/admin/records?stage=unfinish&kind=Colaborador",
      },
      {
        id: "reviewRecordPartners",
        title: "Colaboradores por Revisar",
        navLink: "/admin/records?stage=done&kind=Colaborador",
      },
      {
        id: "preApprovedRecordPartners",
        title: "Colaboradores Pre-Aprobados",
        navLink: "/admin/records?stage=preapproval&kind=Colaborador",
      },
      {
        id: "approvedRecordPartner",
        title: "Colaboradores Aprobados",
        navLink: "/admin/records-approved?kind=Colaborador",
      },
      // {
      //   id: "detailRecordPartners",
      //   title: "Detalle de registro",
      //   navLink: "/admin/record-detail",
      // },
      // {
      //   id: "afterApproval",
      //   title: "Despues de aprobado",
      //   navLink: "/admin/partner-after-approval",
      // },
    ],
  },
  {
    id: "projects",
    title: "Proyectos",
    icon: <Work set="curved" className="remix-icon" />,
    items: [
      {
        id: "projectNew",
        title: "Nuevo Proyecto",
        navLink: "/admin/projects/item?operation=new",
        // navLink: "/admin/projects/item?operation=update",
        // navLink: "/admin/projects/item?operation=finish",
      },
      {
        id: "proyectsList",
        title: "Listado de Proyectos",
        navLink: "/admin/proyects/list",
      },
      // {
      //   id: "projectEdit",
      //   title: "Editar Proyecto",
      //   navLink: "/admin/project/edit",
      // },
    ],
  },
  {
    id: "users",
    title: "Usuarios",
    icon: <RiTeamFill set="curved" className="remix-icon" />,
    items: [
      {
        id: "usersNew",
        title: "Nuevo Usuario",
        navLink: "/admin/users/add-edit",
      },
      {
        id: "usersList",
        title: "Listado de Usuarios",
        navLink: "/admin/users/list",
      },
      // {
      //   id: "userEdit",
      //   title: "Editar Usuario",
      //   navLink: "/admin/user/edit",
      // },
    ],
  },
];

export default pages;
