import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import {
  FunctionsProvider,
  StorageProvider,
  FirestoreProvider,
  AuthProvider,
  useFirebaseApp,
  AppCheckProvider,
} from "reactfire";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

import { currentUser, logout } from "./redux/user/userActions";
import Router from "./router/Router";

import AppLocale from "./languages";


const APP_CHECK_TOKEN = "6LfdCGYgAAAAAP3PUnkZDMO8xzr2D_RZwMygF4PZ";
const APP_TESTING_TOKEN = "B3D621C7-31CC-4C41-8A1A-7F1D914444BD";

export default function App() {
  // Redux
  const customise = useSelector((state) => state.customise);
  const dispatch = useDispatch();

  // Lang
  const currentAppLocale = AppLocale[customise.language];

  // Firebase
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const functions = getFunctions(app);
  const storage = getStorage(app);
  const firestore = getFirestore(app);
  
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true,
  });

  useEffect(() => {
    document.querySelector("html").setAttribute("lang", customise.language);
  }, [customise]);

  useEffect(() => {
    // const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // detaching the listener
      if (user) {
        const {
          uid,
          displayName,
          email,
          emailVerified,
          // phoneNumber,
          // photoURL,
        } = user;

        dispatch(
          currentUser({
            uid,
            displayName,
            email,
            emailVerified,
            // phoneNumber,
            // photoURL,
          })
        );
        // console.log(user)
      } else {
        dispatch(logout());
        // No user is signed in...code to handle unauthenticated users.
      }
    });
    return () => unsubscribe(); // unsubscribing from the listener when the component is unmounting.
  }, []);

  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={customise.direction}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <AppCheckProvider sdk={appCheck}>
          <AuthProvider sdk={auth}>
            <FunctionsProvider sdk={functions}>
              <StorageProvider sdk={storage}>
                <FirestoreProvider sdk={firestore}>
                  <Router />
                </FirestoreProvider>
              </StorageProvider>
            </FunctionsProvider>
          </AuthProvider>
        </AppCheckProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
