import React,{useState,useEffect} from "react";
import { useLocation, Link } from "react-router-dom";

import { useSelector } from 'react-redux';

import {
    Menu,
} from "antd";

import navigationNone from "../../../../navigation/vertical";
import navigationSupplier from "../../../../navigation/vertical-supplier";
import navigationPartner from "../../../../navigation/vertical-partner";
import navigationAdmin from "../../../../navigation/vertical-admin";
import navigationReviewer from "../../../../navigation/vertical-reviewer";

const { SubMenu } = Menu;

const navs = {
  Proveedor: navigationSupplier,
  Revisor: navigationReviewer,
  Colaborador: navigationPartner,
  Administrador: navigationAdmin,
};

export default function MenuItem(props) {
    const [menuItems, setmenuItems] = useState([]);
    const { onClose } = props;

    // Redux
    const customise = useSelector(state => state.customise)
    const user = useSelector((state) => state.user);

    // let navigation = navs[user.roles[0]] ?? navigationNone;
    // console.log("MenuItem",user);

    // Location
    const location = useLocation();
    const { pathname } = location;

    const splitLocation = pathname.split("/")

    // Menu
    const splitLocationUrl =
        splitLocation[splitLocation.length - 2] +
        "/" +
        splitLocation[splitLocation.length - 1];

    // const menuItem = 
    

    useEffect(() => {
        const navigation = navs[user.roles[0]] ?? navigationNone; 
        setmenuItems(
          navigation.map((item, index) => {
            if (item.header) {
              return (
                <Menu.ItemGroup
                  key={index}
                  title={item.header}
                ></Menu.ItemGroup>
              );
            }

            if (item.items) {
              return (
                <SubMenu key={item.id} icon={item.icon} title={item.title}>
                  {item.items.map((childrens, index) => {
                    if (!childrens.items) {
                      const childrenNavLink = childrens.navLink.split("/");

                      return (
                        // Level 2
                        <Menu.Item
                          key={childrens.id}
                          className={
                            splitLocationUrl ===
                            childrenNavLink[childrenNavLink.length - 2] +
                              "/" +
                              childrenNavLink[childrenNavLink.length - 1]
                              ? "ant-menu-item-selected"
                              : "ant-menu-item-selected-in-active"
                          }
                          onClick={onClose}
                        >
                          <Link to={childrens.navLink}>{childrens.title}</Link>
                        </Menu.Item>
                      );
                    } else {
                      return (
                        // Level 3
                        <SubMenu key={childrens.id} title={childrens.title}>
                          {childrens.items.map((childItem, index) => {
                            const childrenItemLink =
                              childItem.navLink.split("/");

                            return (
                              <Menu.Item
                                key={childItem.id}
                                className={
                                  splitLocationUrl ===
                                  childrenItemLink[
                                    childrenItemLink.length - 2
                                  ] +
                                    "/" +
                                    childrenItemLink[
                                      childrenItemLink.length - 1
                                    ]
                                    ? "ant-menu-item-selected"
                                    : "ant-menu-item-selected-in-active"
                                }
                                onClick={onClose}
                              >
                                <Link to={childItem.navLink}>
                                  {childItem.title}
                                </Link>
                              </Menu.Item>
                            );
                          })}
                        </SubMenu>
                      );
                    }
                  })}
                </SubMenu>
              );
            } else {
              const itemNavLink = item.navLink.split("/");

              return (
                // Level 1
                <Menu.Item
                  key={item.id}
                  icon={item.icon}
                  onClick={onClose}
                  className={
                    splitLocation[splitLocation.length - 2] +
                      "/" +
                      splitLocation[splitLocation.length - 1] ===
                    itemNavLink[itemNavLink.length - 2] +
                      "/" +
                      itemNavLink[itemNavLink.length - 1]
                      ? "ant-menu-item-selected"
                      : "ant-menu-item-selected-in-active"
                  }
                >
                  <Link to={item.navLink}>{item.title}</Link>
                </Menu.Item>
              );
            }
          })
        );
    }, [user.roles[0]]);

    return (
        <Menu
            mode="inline"
            defaultOpenKeys={[
                splitLocation.length === 5
                    ? splitLocation[splitLocation.length - 3]
                    : null,
                splitLocation[splitLocation.length - 2],
            ]}
            theme={customise.theme == "light" ? "light" : "dark"}
        >
            {menuItems}
        </Menu>
    );
};