import { CloseSquare, PaperPlus } from "react-iconly";
import IntlMessages from "../../layout/components/lang/IntlMessages";

const pages = [
  // {
  //     header: <IntlMessages id="sidebar-pages" />,
  // },
  // {
  //   id: "dataDocumentsPartners",
  //   title: "Datos y Documentos (colaboradores)",
  //   icon: <PaperPlus set="curved" className="remix-icon" />,
  //   navLink: "/supplier/info-docs",
  // },
  // {
  //   id: "dataDocumentosSuppliers",
  //   title: "Datos y Documentos (Proveedores)",
  //   icon: <PaperPlus set="curved" className="remix-icon" />,
  //   navLink: "/partner/info-docs",
  // },
  // {
  //   header: "Proveedores",
  // },
  // {
  //   id: "recordsSuppliers",
  //   title: "Registro Proveedores",
  //   // icon: <CloseSquare set="curved" className="remix-icon" />,
  //   items: [
  //     {
  //       id: "incompleteRecordSupplier",
  //       title: "Registros Incompletos",
  //       navLink: "/admin/records?status=incomplete&kind=supplier",
  //     },
  //     {
  //       id: "reviewRecordSupplier",
  //       title: "Registros por revisar",
  //       navLink: "/admin/records?status=complete&kind=supplier",
  //     },
  //     {
  //       id: "approvedRecordSupplier",
  //       title: "Registros Aprobados",
  //       navLink: "/admin/records?status=approved&kind=supplier",
  //     },
  //     {
  //       id: "detailRecordSupplier",
  //       title: "Detalle de registro",
  //       navLink: "/admin/record-detail",
  //     },
  //   ],
  // },
  // {
  //   header: "Colaboradores",
  // },
  // {
  //   id: "recordsPartners",
  //   title: "Registro Colaboradores",
  //   // icon: <CloseSquare set="curved" className="remix-icon" />,
  //   items: [
  //     {
  //       id: "incompleteRecordPartners",
  //       title: "Registros Incompletos",
  //       navLink: "/admin/records?status=incomplete&kind=partner",
  //     },
  //     {
  //       id: "reviewRecordPartners",
  //       title: "Registros por revisar",
  //       navLink: "/admin/records?status=complete&kind=partner",
  //     },
  //     {
  //       id: "approvedRecordPartner",
  //       title: "Registros Aprobados",
  //       navLink: "/admin/records?status=approved&kind=partner",
  //     },
  //     {
  //       id: "detailRecordPartners",
  //       title: "Detalle de registro",
  //       navLink: "/admin/record-detail",
  //     },
  //     {
  //       id: "afterApproval",
  //       title: "Despues de aprobado",
  //       navLink: "/admin/partner-after-approval",
  //     },
  //   ],
  // },
  // {
  //   header: "Administracion",
  // },
  // {
  //   id: "projects",
  //   title: "Proyectos",
  //   // icon: <CloseSquare set="curved" className="remix-icon" />,
  //   items: [
  //     {
  //       id: "projectNew",
  //       title: "Nuevo Proyecto",
  //       navLink: "/admin/project/new",
  //     },
  //     {
  //       id: "proyectsList",
  //       title: "Listado de Proyectos",
  //       navLink: "/admin/proyect/list",
  //     },
  //     {
  //       id: "projectEdit",
  //       title: "Editar Proyecto",
  //       navLink: "/admin/project/edit",
  //     },
  //   ],
  // },
  // {
  //   id: "users",
  //   title: "Usuarios",
  //   // icon: <CloseSquare set="curved" className="remix-icon" />,
  //   items: [
  //     {
  //       id: "usersNew",
  //       title: "Nuevo Usuario",
  //       navLink: "/admin/user/new",
  //     },
  //     {
  //       id: "usersList",
  //       title: "Listado de Usuarios",
  //       navLink: "/admin/user/list",
  //     },
  //     {
  //       id: "userEdit",
  //       title: "Editar Usuario",
  //       navLink: "/admin/user/edit",
  //     },
  //   ],
  // },
];

export default pages